.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*table css--*/
 
@media only screen and (max-width: 1150px) {  
  /* Force table to not be like tables anymore */
  .action_icon{
	  width: 100% !important;
  }
  #no-more-tables table, 	#no-more-tables thead, 	#no-more-tables tbody, 	#no-more-tables th, 	#no-more-tables td, 	#no-more-tables tr {
	  display: block;
		float: left;
		clear: both;
		width: 100%;	
  } 	
	/* Hide table headers (but not display: none;, for accessibility) */
	
	#no-more-tables tr { border: 1px solid #ccc; }
	#no-more-tables td {
	/* Behave  like a "row" */	
		border: none;	
		border-bottom: 1px solid #eee; 	
		position: relative;	
		padding-left: 50%!important; 	
		white-space: normal;	
		text-align:left;	
	}
	#no-more-tables thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	#no-more-tables td:before {
		/* Now like a table header */	
		position: absolute;	
		/* Top/left values mimic padding */	
		top: 3px;	
		left: 6px;	
		width: 45%; 
		padding-right: 10px; 	
		white-space: nowrap;	
		text-align:left;	
		font-weight: bold;	
	}	
		/*	Label the data	*/
	#no-more-tables td:before { 
		content: attr(data-title); 
	}	
	#no-more-tables td{	
		height: 100%;
		width:auto !important;
		padding-top: 6px;
    	padding-bottom: 6px;
	}.hide_td{
		display:none!important;
	}	
	#no-more-tables th{	
		padding-top: 5px;	
	}
	
}
@media only screen and (max-width: 440px) { 
	#no-more-tables td:before,#no-more-tables td {
		font-size: 14px;
	}#no-more-tables td {
		text-align:right;
	}.action_icons {
		font-size: 13px;
	}
}
/*new css*/
body{
	font-family: 'Open Sans', sans-serif !important;
}
.header_div{
	margin:0;width:100%;background:#51585f;padding-bottom: 4px;
}.top_main_div{
	width:100%;
}.top_ul{
	width:100%;
}.top_text{
	color:#fff !important;font-weight: bold;font-size:19px;
}.social-icons1{
	width:100%;padding-top: 4px;
}.social-icons1 li {
    float: none;
    display: inline-block;
	padding-left: 10px;
}.social-icons1 li a i{
   color: white;
    width: 34px;
    height: 34px;
    font-size: 20px;
    text-align: center;
    line-height: 33px;
    border: 2px solid #fff;
    font-weight: bold;
}.head_nav{
	width:100%;margin:0;padding:0px !important;
}.head_ul{
	width:100%;
}.top_bars{
	font-size: 19px;color: black;
}.top_nav_btn{
	border-color: black;
    margin-left: auto;
    margin-right: 0;
    position: absolute;
    right: 20px;
    top: -42px;
}.navbar-toggler-icon {
    width: 1em;
    height: 1em;
}
.navbar-toggler{
	padding: 8px 10px !important;
}.navbar-expand-lg .navbar-nav .nav-link {
    padding: 0 10px !important;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 40px;
    text-transform: uppercase;
    background: transparent;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
	color:black;
	font-size: 13px;
    font-weight: bold;
}.top_right_div{
	margin: auto;
}.app_logo{
	height:56px;
}.reg_btn{
	background: #991870 !important;
    color: white !important;
    border-radius: 10px;
	margin-left: 10px;
	text-transform:inherit !important;
	cursor:pointer;
	
    text-align: center;
    font-size: 16px !important;
	border-color: #991870;
}.log_btn{
	background: green !important;
    color: white !important;
    border-radius: 10px;
	margin-left: 10px;
	cursor:pointer;
	
    text-align: center;
    font-size: 16px !important;
	text-transform:inherit !important;
	border-color: green;
}.navbar-nav .nav-item .effect-underline:hover {
	background: #991870 !important;
    color: white !important;
    border-radius: 10px;
}#demo{
	background-color:transparent;width: 100%;
}.arrowss{
	color: white;font-size: 30px;border: 2px solid white;padding: 10px 12px 10px 12px;border-radius: 50%;
}.carousel-item img{
	width:100%;
}.divider_div{
	max-width:1500px !important;padding-top: 50px;padding-bottom: 50px;
}.top_icons {
    text-align: center;
}.top_icons i {
    height: 150px;
    width: 150px;
    font-size: 60px;
    text-align: center;
    line-height: 140px;
    border-radius: 50%;
    color: white;
	transition: all 600ms ease;
}.top_icons i:hover {
    transform: rotateY(360deg);
}.top_icons h3 {
    font-size: 28px;
    padding-top: 25px;
    color: white;
	font-weight: 600;
}.video_div{
	border: 3px solid #991870;padding: 2px;background: white;border-radius: 12px;
}.about_div{
	padding-bottom:40px;padding-top:24px;
}#about_us{
	border-top: 6px solid #991870;
}.head_text {
    color: #1e1f36;
    text-align: center;
    position: relative;
    font-weight: 600;
    font-size: 40px;
    font-family: 'Open Sans', sans-serif;
}.divider .outer-line {
    border-bottom: 2px solid #1e1f36;
    width: 25%;
}.divider span {
    display: inline-block;
    vertical-align: middle;
}.divider .image {
    padding-right:6px;
}.about_text {
    background: white;
    padding: 30px;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
	height: 100%;
}.about_text h1 {
    border-bottom: 1px solid #1e1f36;
    padding-bottom: 5px;
    color: #1e1f36;
	font-weight:bold;
}.about_text p {
    font-size: 16px;
    text-align: justify;
    line-height: 27px;
    color: #1e1f36;
}.container-width{
	max-width: 1700px !important;
}.divider1{
	background-image:url('images/div_back1.jpg');background-size:cover;background-repeat:no-repeat;border-bottom: 6px solid #991870;
}.divider1 .row{
	margin:0;
}.register_div p {
    color: white;
    font-size: 16px;
}.abt_btn {
    background: #991870;
    padding: 10px 30px 10px 30px;
    font-size: 20px;
    border-radius: 10px;
    border: 2px solid #fff;
    font-weight: bold;
    color: white !important;
}.divider1 .container{
	padding-top:50px;
	padding-bottom:50px;
}.register_div{
	text-align:center;
}.team_div{
	max-width:1600px !important; padding-bottom:40px;padding-top:40px;
}.members h3{
    color: #1e1f36;
    font-weight: 600;
    font-size: 28px;
}.members span{
	font-size: 19px;
    color: #1e1f36;
}.teamm{
	border-bottom: 6px solid #991870;
}.team_member{
	box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
	padding:30px;
	height: 100%;
	background: white;
    border: 1px solid #dddddd;
	border-bottom: 5px solid #991870;
	border-radius: 8px;
}.team_member img{
    border-radius: 50%;
    border: 5px solid #0ea5df;
    padding: 2px;
	transition: all 300ms ease;
}.team_member img:hover{
	transform: scale(1.1); 
}
.members p{
	font-size: 16px;
    color: #1e1f36;
	text-align:justify;
	padding-top: 16px;
	line-height:23px;
}.members h5{
	margin-bottom:3px;
	color:#1e1f36;
	font-size:20px;
}.mem_h4{
	font-size:19px;
	text-align:center;
	padding-top:15px;
	font-weight: bold;
	color:#1e1f36;
}.mem{
	padding: 35px;
}.faq{
	background:#212529;border-bottom:6px solid #991870;
}#faq{
	padding-bottom:40px;max-width: 1900px !important;padding-top:80px;padding-bottom:80px;
}.accordion-section .panel-default > .panel-heading {
    border: 0;
    background:#991870;
    padding: 0;
	position: relative;
}.panel-heading i{
	position: absolute;
    right: 10px;
    font-size: 36px;
    color: white;
    top: 5%;
}
.accordion-section .panel-default .panel-title a {
    display: block;
    font-size: 1.5rem;
}
.accordion-section .panel-default .panel-title a:after {
    /* font-family: 'FontAwesome'; */
    /* font-style: normal; */
    /* font-size: 2rem; */
    /* content: "\f106"; */
    /* color: #fff; */
    /* float: right; */
    /* margin-top: -4px; */
}
.accordion-section .panel-default .panel-title a.collapsed:after {
    /* content: "\f107"; */
}
.accordion-section .panel-default .panel-body {
    font-size: 1.2rem;
}.panel-default{
	height:100%;
}.panel-title a{
	color: #fff;
    font-size: 18px !important;
	padding-right: 24px;
}.panel-body p{
	color:#fff;
	font-size:16px;
	text-align:justify;
	line-height:24px;
}.panel-body ul li{
	color:#fff;
	font-size:16px;
	text-align:justify;
	line-height:24px;
}.panel-body{
	border:1px solid #fff;
	padding-top:8px;
}.p-3 {
    padding: 7px 5px 2px 5px !important;
    border: 2px solid white !important;
	border-radius:10px;
}#contact_box{
	background:url(images/cont_backk1.jpg);width:100%;background-repeat:no-repeat;background-size:cover;
}#contact_box .cont_row{
	padding-top: 50px;padding-bottom:50px;border-bottom: 6px solid #991870;
}.cont_div{
	padding-bottom:60px;padding-top:60px;max-width:1100px
}.cont_box_div{
	background: #fff;padding-bottom: 30px;padding-top: 30px;border-radius: 8px;border-top: 8px solid #991870;
}.clipped h1{
	text-align: center;
    color: #1e1f36;
    font-weight: 600;
    font-size: 28px;
}.clipped p{
	color:#1e1f36;text-align:center;font-size:16px;margin-bottom: 0;
}.contact_formm{
	padding-left:0;padding-right:0px;
}.alert-danger{
	width:100%;
}.alert-success{
	width: 100%;
}.accept_msg{
	text-align: center;
    background: #d4edda;
    color: #155724;
    padding: 16px;
	border-radius: 10px;
	margin:50px 0px;
}.accept_msg h4{
	font-weight: 600;
}.accept_msg p{
	font-size: 17px;
	margin-bottom: 0;
}#loader1{
	height: 35px;margin-right: 10px;display: none;
}.contact_formm label {
    font-size: 16px;
    color: #1e1f36;
    padding-top: 12px;
}.contact_formm input {
    background: transparent !important;
    border-color: #1e1f36;
}.contact_formm textarea {
    background: transparent !important;
    border-color: #1e1f36;
}.cont_btn {
    background: #991870;
    color: white !important;
    font-weight: bold !important;
    padding: 12px 25px 12px 25px;
    font-size: 19px;
    border-radius: 10px;
}.footer{
	margin:0;background:url(images/backfoot1.png);width:100%;background-repeat:no-repeat;background-size:cover;
}.foot_cont{
	padding-top:40px;padding-bottom:30px;
}.foot_logo{
	margin-bottom:20px;
}.foot_logo img{
	height:56px;
	/* border-radius: 10px;border-bottom: 20px solid white; */
}.foot_logo p{
	color:#a3a3a3;text-align:justify;margin-top: 16px;padding-left:10px;
}.foot_cont h5{
	color:#a3a3a3;font-weight:bold;
}.foot_div4 p{
	color:#a3a3a3;text-align:justify;margin-top: 16px;
}.copyy{
    margin: 0;
    text-align: center;
    color: #a3a3a3;
    font-size: 16px;
}.footer-helplinks li a i{
   background: #991870;
    color: white;
    width: 40px;
    height: 40px;
    font-size: 28px;
    text-align: center;
    line-height: 38px;
	left: -50px;
	position:absolute;
	border: 2px solid #fff;
	border-radius: 10px;
}.footer-helplinks li a{
	position:relative;
	left:50px;
	color:white;
	font-weight:bold;
}.footer-helplinks li a span{
	color:white;
	font-weight:normal !important;
}.social-icons li {
    float: none;
    display: inline-block;
	padding-right: 10px;
	vertical-align: top;
}.social-icons li a i{
   background: #991870;
    color: white;
    width: 40px;
    height: 40px;
    font-size: 28px;
    text-align: center;
    line-height: 38px;
	border: 2px solid #fff;
	border-radius: 10px;
}.footer-helplinks{
	padding-left: 5px !important;
}.social-icons{
	padding-left: 5px !important;
}.menu{
	padding-left: 5px !important;
}
@media only screen and (min-width: 1500px) and (max-width: 1920px) {
   .top_left_div{
		padding:0px !important;
	}.top_nav_row{
		margin-top:4px;
	}
}
@media only screen and (min-width: 1281px) and (max-width: 1500px) {
	.team_member .col-sm-12 img{
		height:170px !important;
	}.top_text{
		padding-top:0px !important;
	}.navbar-expand-lg .navbar-nav .nav-link {
		padding: 0 6px !important;
		font-size:12px !important;
	}.top_nav_row{
		margin-top:4px;
	}.reg_btn{
		font-size:12px !important;
	}.log_btn{
		font-size:13px !important;
	}.head_user{
		font-size:14px !important;
	}
}
@media only screen and (min-width: 320px) and (max-width: 991px) {
	.large_screen{
		display:none !important;
	}.small_screen{
		display:block !important;
	}.right_div .form-control{
		width:175px;
	}.top_right_div{
		padding-right: 0;
	}.video_div{
		height: 400px !important;
	}.dropdown-menu1{
		right:-17px !important;
	}.dropdown-item {
		padding: 3px 8px 3px 8px !important;
		font-size: 16px !important;
		white-space: normal !important;
    	width: 200px !important;
	}
}
@media only screen and (min-width: 1066px) and (max-width: 1280px) {
   .top_text{
		font-size:16px !important;
		padding-top:0px !important;
	}#main_div1{
		width:30% !important;
	}#main_div2{
		width:70% !important;
	}.container{
		max-width:100% !important;
	}.navbar-brand img{
		height:40px;
	}.top_nv_link{
		padding-top:8px !important;
	}.main_slider{
		top:33% !important;
	}.navbar-expand-lg .navbar-nav .nav-link {
		padding: 0 2px !important;
		font-size:9px !important;
	}.nav-link{
		font-size:14px !important;
	}.app_logo{
		height:48px !important;
	}.top_left_div{
		margin:auto;
	}.reg_btn{
		font-size:12px !important;
	}.log_btn{
		font-size:12px !important;
	}.top_right_div{
		padding-left: 0;
		padding-right: 10px;
	}
}@media only screen and (min-width: 1025px) and (max-width: 1065px) {
	.top_text{
		font-size:16px !important;
		padding-top:0px !important;
	}#main_div1{
		width:30% !important;
	}#main_div2{
		width:70% !important;
	}.container{
		max-width:100% !important;
	}.navbar-brand img{
		height:40px;
	}.top_nv_link{
		padding-top:8px !important;
	}.main_slider{
		top:33% !important;
	}.navbar-expand-lg .navbar-nav .nav-link {
		padding: 0 2px !important;
		font-size:9px !important;
	}.nav-link{
		font-size:14px !important;
	}.app_logo{
		height:48px !important;
	}.top_left_div{
		margin:auto;
	}.reg_btn{
		font-size:12px !important;
	}.log_btn{
		font-size:12px !important;
	}.top_right_div{
		padding-left: 0;
		padding-right: 10px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.top_right_div{
		padding-left: 0;
		padding-right: 10px;
	}
   .carousel-control-next, .carousel-control-prev {
		top: 185px !important;
	}.top_text{
		font-size:15px !important;
	}.main_slider{
		top:6% !important;
	}.heading{
		font-size:40px !important;
	}.team_member .col-sm-12 img{
		height:170px !important;
	}.foot_logo img{
		height:56px !important;
	}.container {
		max-width: 100% !important;
	}.app_logo{
		height:48px !important;
	}.top_left_div{
		margin:auto;
	}.top_right_div{
		position:relative;
	}.navbar-expand-lg .navbar-nav .nav-link {
		padding: 0 2px !important;
		font-size:8px !important;
	}.navbar-nav{
		flex-direction:row !important;
	}#demo{
		margin-top: 50px !important;
	}.nav-link{
		font-size:14px !important;
	}.left_div{
		width: 20% !important;
	}.right_div{
		width:80% !important;
	}
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
   .heading{
		font-size:22px !important;
		margin-bottom: 0px !important;
	}.top_nv_link a{
		font-size:15px !important;
	}.heading1{
		font-size:15px !important;
	}.slidep a{
		font-size:13px !important;
	}
	.carousel-control-next, .carousel-control-prev {
		top: 0px !important;
	}.top_text{
		
	}.navbar-brand img{
		height:30px;
	}#video_btn{
		margin-top:17px !important;
	}.social-icons1 li{
		padding-right:4px !important;
	}.abt_btn{
		font-size:19px !important;
	}.team_member .col-sm-12{
		text-align:center;
	}.members h3{
		text-align:center;
	}.foot_logo img{
		height:56px !important;
	}.container {
		max-width: 100% !important;
	}.col-xs-10 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333% !important;
	}.main_slider{
		top:8% !important;
	}#demo{
		margin-top: 50px !important;
	}.app_logo{
		height:48px !important;
	}.top_left_div{
		margin:auto;
	}.coach_filter .col-xs-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}.coach_filter .col-xs-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}.sear_coach{
		display:block !important;
		background: rgb(14, 165, 223);
		color: white;
		width: 36px;
		height: 36px;
		font-size: 24px !important;
		text-align: center;
		line-height: 33px !important;
		border-radius: 4px;
	}.coach_filter .row .col-sm-2 .add_btn{
		display:none !important;
	}.left_div{
		width: 25% !important;
	}.right_div{
		width:75% !important;
	}
}@media (min-width: 320px) and (max-width: 575px) {
	.btn_stylee{
		width:50%;
		margin-top: 10px;
	}.btn_stylee1{
		float: left;
	}.btn_stylee button{
		font-size: 14px;
		font-weight: 600;
	}.logout_icon{
		display: block !important;
		padding-right: 12px;
	}.logout_btn{
		display: none;
	}.head_user{
		font-size: 12px !important;
		padding-right: 0;
	}.top_btns{
		display: none;
	}.top_iconss{
		display: block !important;
		padding-left: 15px !important;
	}.head_user:before{
		display: none;
	}.top_user_name{
		display: none;
	}.head_user .fa-caret-down{
		font-size: 24px;
		position: absolute;
		right: 5px;
		bottom: 4px;
	}.top_img img {
		height: 38px !important;
		border-radius: 4px;
	}.head_nav .ul1{
		top:-49px !important;
	}.left_div{
		width: 100% !important;
	}.right_div{
		width:100% !important;
		float:left !important;
	}.right_div .add_btn{
		font-size: 14px;
		padding:6px;
	}
	.right_div .row{
		float:left !important;
	}.nav-tabs .nav-item .nav-link{
		padding: 9px;
	}
}
@media (min-width: 320px) and (max-width: 480px) {
	.app_logo{
		height:48px !important;
	}.top_left_div{
		margin:auto;
		padding-left: 10px !important;
	}
	.carousel-control-next, .carousel-control-prev {
		top: 0px !important;
	}
	.navbar-brand{
		font-size:18px !important;
	}.main_slider{
		top:24% !important;
	}.heading{
		font-size:12px !important;
		margin-bottom: 0px !important;
	}.top_nv_link a{
		font-size:15px !important;
	}.heading1{
		font-size:9px !important;
		margin-top:0px !important;
	}.slidep a{
		font-size:9px !important;
	}.top_main_div{
		max-width:100% !important;
		padding:10px !important;
	}.slidep{
		margin-top:0px !important;
	}.top_text{
		font-size:12px !important;
	}.main_ul{
		flex-direction: row !important;
		/* padding-top: 10px; */
	}.navbar-brand img{
		height:20px;
	}.top_nv_link a{	
		font-size:10px !important;	
	}#video_btn{
		margin-top:17px !important;
	}.social-icons1 li{
		padding-right:4px !important;
	}.social-icons1 li a i{
		width: 25px !important;
		height: 25px !important;
		font-size: 15px !important;
		line-height:20px !important;
	}.top_nv_link{
		padding-top:0 !important;
	}.abt_btn{
		font-size:16px !important;
	}.team_member .col-sm-12{
		text-align:center;
	}.members h3{
		text-align:center;
	}.foot_logo img{
		height:56px !important;
	}.container {
		max-width: 100% !important;
	}.col-xs-10 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333% !important;
	}#demo{
		margin-top: 50px !important;
	}.coach_filter .col-xs-9 .form-control{
		width:100% !important;
	}.coach_filter .col-xs-9{
		margin-top: 10px;
		padding-left: 0;
	}.coach_filter .row .col-xs-10{
		padding-left: 0;
	}.sear_coach{
		display:block !important;
		background: rgb(14, 165, 223);
		color: white;
		width: 36px;
		height: 36px;
		font-size: 24px !important;
		text-align: center;
		line-height: 33px !important;
		border-radius: 4px;
	}.coach_filter .row .col-sm-2 .add_btn{
		display:none !important;
	}
}.col-xs-6 { 
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}.col-xs-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}.col-xs-2{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}.col-xs-10 {
    /* -webkit-box-flex: 0; */
    /* -ms-flex: 0 0 83.333333%; */
    /* flex: 0 0 83.333333%; */
    /* max-width: 83.333333%; */
}.top-nav-collapse {
  background: #fff;
  z-index: 999999;
  top: 0px !important;
  min-height: 58px;
  box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.06);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  background: #fff !important;
}.carousel-indicators li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}.carousel-indicators .active {
    background-color: #991870;
    border: 2px solid white;
	margin-top: 7px;
}.carousel-indicators li {
    width: 15px;
    height: 15px;
}.modal-open{
	overflow: auto !important;
    padding-right: 0px !important;
}.modal_head{
	background: #991870;
    color: white;
    padding: 8px;
}.modal_title{
	font-weight: bold;
}.close{
	opacity: 1 !important;
    font-size: 28px;
	margin:0 !important;
	padding:0 !important;
}.close span:active{
	border:none !important;
}.close span{
	color:white;
}.modal_btn{
	background: #991870;
    border-color: #991870;
    font-weight: bold;
}.ul3 li{
	float: none !important;
    display: inline-block !important;
}.ul3 {
	float:right;
	padding-left:0;
}.main_slider{
	position: absolute;top:30%;left: 16%;color: white;
}.heading1{
	font-size: 22px;
    font-family: 'Open Sans', sans-serif;
    padding-top: 10px;
}.outer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.52);
}.heading{
	font-family: 'Open Sans', sans-serif;font-size:44px;font-weight:300;
}.slidep{
	margin-top: 40px;
}.small_screen{
	display:none;
}.logout_btn{
	background:rgb(14, 165, 223) !important;
}.head_user{
	color: #991870 !important;
    font-size: 24px;
    font-weight: bold;
	cursor:pointer;
}.dropdown-menu1{
	background:white;position:absolute;z-index:1;right:0;box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);border-top: 3px solid #991870;
}.dropdown-menu1 .fa-caret-up {
  position: absolute;
  color: #991870;
    font-size: 32px;
    top: -24px;
    right: 15px;
}.dropdown-item{
	padding: 7px 18px 7px 18px !important;
    font-size: 19px;
	cursor:pointer;	
}.dropdown-item i{
	color:#991870;
	width:24px;
}.dropdown-item:active{
	background:#fff !important;
	color:black !important;
	border:none !important;
}.nav-link{
	font-size: 19px;
    font-weight: 600;
    padding-left: 10px;
	cursor:pointer;
}.table-responsive{
	margin-top:10px;
	padding-bottom:20px;
	background:white;
	box-shadow: 0 0 7px #cccccc73;
}.table-responsive table thead{
	background: #991870;
    color: white;
    font-size: 19px;
}.table-responsive table tbody tr td{
    font-size: 16px;
}.table-responsive tbody tr:nth-child(even){
  background-color: #dee2e66b;
}.edit{
	cursor:pointer;
	margin-right: 10px;
}.delete{
	cursor:pointer;
}.edit_icon{
	background: #007bff;
}.action_icons{
	width: 30px;
    height: 28px;
    text-align: center;
    line-height: 28px !important;
    border-radius: 5px;
    color: white;
}.del_icon{
	background:red;
}.request_icon{
	background:#a90ca9;
}.head_user:before{
	content: '';
    border-left: 2px solid #c0c2c3d6;
    position: absolute;
    height: 72%;
    left: 0;
    top: 16%;
}#loader{
	height: 35px;margin-right: 10px;
}.spinner-border-sm {
    width: 1.1rem !important;
    height: 1.1rem !important;
}.btn-primary:focus{
	background:#991879 !important;
	border-color:#991879 !important;
	box-shadow:none !important;
}.btn-primary:active{
	background:#991879 !important;
	border-color:#991879 !important;
}.btn-primary:hover{
	background:#991879 !important;
	border-color:#991879 !important;
}.add_btn{
	background: rgb(14, 165, 223);
    color: white;
    font-size: 18px;
}.delete_modal{
	text-align:center;
}.delete_modal i{
	color: red;
    font-size: 35px;
    border: 2px solid red;
    width: 60px;
    height: 68px;
    line-height: 66px;
    border-radius: 50%;
}.delete_modal h4{
	padding-top: 20px;
}.delete_modal p{
	font-size: 19px;
}.delete_modal_footer{
	justify-content: center;
}.modal_del_btn{
	background: red;
    border-color: red;
    font-size: 19px;
    font-weight: 600;
    border-radius: 6px;
}.modal_cencel_btn{
    font-size: 19px;
    font-weight: 600;
    border-radius: 6px;
}.page-item{
	cursor:pointer;
}.page-link{
	color:black;
}.activee{
	background: #007bff !important;
    color: white !important;
}.disabledd{
	color: gray !important;
    cursor: no-drop !important;
}.scroll_div{
	padding-top:50px;
}.slidep .btn{
	font-size:18px !important;
}.nav-tabs li a{
	color:black;
	background:transparent;
	border: 1px solid #cfd2d4 !important;
}.nav-tabs li .active{
	color:white !important;
	background:#991870 !important;
}.top_letter{
	background: white!important;
    color: rgb(14, 165, 223);
    border-radius: 50%;
    font-size: 17px;
    font-weight: bold;
    min-width: 26px;
    min-height: 26px;
    display: inline-block;
    max-height: 42px;
	text-align: center;
}.top_pipe{
	border-left: 2px solid #c0c2c3d6;
    padding-right: 10px;
}
.top_img img{
	height: 44px;
    border: 2px solid #0ea5df;
    padding: 2px;
    margin-right: 4px;
    object-fit: cover;
    width: 44px;
}.projects_back{
	background-image:url('images/pattern1.png');background-repeat: no-repeat;background-size: cover;
}.under_projects_back{
	background-color:#212529d4;
	width:100%;
	padding: 48px;
}.project_div h3{
	color: #1e1f36;
    font-weight: 600;
    font-size: 28px;
}.project_div h5{
	margin-bottom: 3px;
    color: #1e1f36;
    font-size: 17px;
}.project_div p{
	font-size: 16px;
    color: #1e1f36;
    text-align: justify;
    padding-top: 16px;
    line-height: 23px;
}.project_div{
	background: white;
    padding: 20px;
	box-shadow:0 0px 10px 0 rgba(0, 0, 0, 0.1);
	margin-bottom: 28px;
}.coach_div img{
	height: 120px;
    border-radius: 100%;
    border: 3px solid #991870;
    padding: 4px;
}.coach_div h3{
	color: #1e1f36;
    font-weight: 600;
    font-size: 24px;
	padding-top:8px;
}.coach_div h5{
	margin-bottom: 3px;
    color: #1e1f36;
    font-size: 17px;
}.coach_div p{
	font-size: 16px;
    color: #1e1f36;
    text-align: justify;
    padding-top: 16px;
    line-height: 23px;
	margin-bottom:8px;
}.coach_div{
	background: white;
    padding: 20px;
	box-shadow:0 0px 10px 0 rgba(0, 0, 0, 0.1);
	margin-bottom: 28px;
}.termss{
	color:#0062cc;
	cursor:pointer;
}.guide p{
	font-size:16px;
	text-align:justify;
}.guide_div{
	max-width:1000px;
}.guide_div .modal-body{
	height: 800px;
    overflow-y: scroll;
}.guide h3{
	font-size:22px;
}.guide h4{
	font-size:19px;
	margin-bottom: 16px;
}#message3{
	height:132px;
}
.edit_div{
	max-width:600px;
}.proj_title{
	font-weight:600;
	cursor:pointer;
}.project_head{
	width: 152px;
    font-weight: 600;
}.table_row{
	border-bottom: 1px solid #6c757d59;
}.project_det tr td{
	padding:6px;
	text-align: left;
}.coaches_modal{
	max-width:1000px;
}.coach_table{
	border-radius :4px;;
}.table-responsive .coach_table .thh{
	border-top: 4px solid #991870;
	background: none;
	color:black !important;
}.table-responsive .coach_table tbody tr:nth-child(odd){
	background-color: #dee2e66b;
}.table-responsive .coach_table tbody tr:nth-child(even){
	background-color: #fff;
}.view_icon{
	background-color: #183499;
}.err_div{
	padding: 10px; border: 1px solid rgb(239, 239, 239); box-shadow: rgb(140, 135, 135) 0px 1px 4px; background: rgb(99, 114, 200); margin: 0px 0px 8px; color: white; border-radius: 5px;
}.cnfrm{
	margin-right: 5px;border:1px solid white
}.ncnfrm{
	border:1px solid white;
}.err_div p{
	font-size: 18px;
    font-weight: 600;
}.disable_icon{
	background-color: #727375ba;
	cursor: no-drop;
}.close_icon{
	background-color: #18998d;

}.rating__icon {
	padding: 4px;
    color: #dddddd;
    font-size: 24px !important;
}.selected_rating{
	color:orange;
}.halfstar {
    position: absolute;
    top: 3px;
    left: 4px;
    width: 8px;
    height: 24px;
    color: orange;
    overflow: hidden;
}.chat_icon{
	background-color: #1e1f36;
}.coach_messages{
	text-align: left;
	height: 312px;
    overflow-y: scroll;
}.coach_messages p{
	font-size: 16px;
	margin-bottom: 0px;
}.msg{
	background: #a7a7a72e;
    padding: 4px 8px 4px 8px;
    border-radius: 6px;
    margin-bottom: 10px;
    margin-right: 8px;
}.msg .p1{
	border-bottom: 1px solid #00000038;
	padding-bottom: 4px;
	color: black;
}
.coach_messages span{
	font-size: 13px;
    font-weight: 600;
}.send_message{
	position:relative;
	border-radius: 4px;
	border:1px solid #dddddd;
}.send_message textarea{
	height: 120px;
    width: 384px;
    border: none;
}.send_message textarea:focus{
	border-color: transparent;
    box-shadow: none;
}
.send_message span{
	top: 8%;
    position: absolute;
    right: 1%;
    background: #991879;
    color: white;
    padding: 4px 6px 4px 6px;
    font-size: 16px;
    font-weight: 600;
}.languages_div li {
	position: relative;
    left: 0px;
	list-style: none;
	margin-bottom: 12px;
}
.languages_div input {
	position: absolute;
	height: 22px;
	width: 22px;
	left: -28px;
}.languages_div{
	height: 214px;
    overflow-y: scroll;
	border: 1px solid #dddddd;
	padding-top: 10px;
	padding-bottom: 10px;
}.msg_notify{
	position: absolute;
    left: -10px;
    background: #299943;
    color: #fff;
    width: 22px;
    height: 22px;
    text-align: center;
    border-radius: 50%;
    font-weight: bold;
    top: -8px;
    font-size: 14px;
}.prf_img{
	position: relative;	
}.prf_img img{
	height: 214px;
    width: 214px;
    object-fit: cover;
    border: 3px solid #0ea5df;
}.prf_img p{
	color: white;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    width: 30%;
    float: right;
	text-align: center;
	position: absolute;
	right: 0;
}.req_icon{
	background-color:#17a2b8;
}.proj_span{
	border-bottom: 1px solid #991879;
    padding: 0px 0px 10px 0px;
    display: block;
}.proj_span1{
	border-top: 1px solid #991879;
    padding: 8px 0px 0px 0px;
	display: block;
	margin-bottom: -4px;
}.up_btn {
    border: 1px solid gray;
    color: gray;
    padding: 8px 20px;
    font-size: 14px;
    width: 100%;
    white-space: normal;
    height: 85px;
    border-radius: 0;
    background: #F0F0F0;
}.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
	opacity: 0;
}.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
	display: inline-block;
	width:100%;
}.rating_div p{
	position: relative;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 0;
}.rating_div span{
	position: absolute;
	right: 0;
	font-size: 15px;
	font-weight: normal;
}.rev{
	text-align: justify;
	font-size: 16px !important;
	font-weight: normal !important;
}.rating_div_img img{
	border:2px solid rgb(14, 165, 223);
	height: 60px;
	padding: 2px;
}.feedbck{
	border-bottom: 1px solid #545b6252;
    padding: 10px 0px;
    margin: 0;
}.show_star{
	font-size: 16px !important;
	position: relative;
	padding: 1px;
}.bronze_c{
	background-color: #fbbd18;
}.silver_c{
	background-color: #0062cc;
}.gold_c{
	background-color: #14ad11;
}.rating_box{
	background: rgb(14, 165, 223);
    color: white;
    font-size: 16px;
    height: 26px;
    width: 28px;
    display: inline-block;
    text-align: center;
	line-height: 25px;
	margin-right: 6px;
	font-weight: 600;
}.nav-tabs .nav-link {
    border-color: #e9ecef #e9ecef #dee2e6;
}.nav-tabs {
    border-bottom: 3px solid #991870;
}.nav-tabs .nav-link {
    border-color: #e9ecef #e9ecef #991870;
}.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #991870;
}
.profile_letter{
	background-color: rgb(14, 165, 223);
    margin-right: 4px;
    height: 80px;
    width: 80px;
    display: block;
    line-height: 80px;
    text-align: center;
}.profile_letter b{
	background: white!important;
    color: rgb(14, 165, 223);
    border-radius: 50%;
    font-size: 24px;
    font-weight: bold;
    min-width: 50px;
    min-height: 50px;
    display: inline-block;
    max-height: 80px;
    text-align: center;
    line-height: 45px;
}.coach_name{
	cursor:pointer;
	font-weight: 600;
}.table thead th{
	font-size: 17px;
}.add_btn {
	font-size: 16px;
	font-weight: 600;
}.articles img{
	width: 100%;
}.articles{
	box-shadow: 0 0 20px rgba(0,0,0,.1);
	height: 100%;
	cursor:pointer;
	background: white;
	padding: 8px;
	border-radius: 10px;
    text-align: justify;
}.articles h4{
	background: #991870;
    color: white;
	padding: 6px 10px 8px;
	font-size: 20px;
}.articles p{
	font-size: 16px;
    margin-bottom: 0;
    color: black;
}.divider_main_div{
	border-top: 6px solid #991870;
	border-bottom: 6px solid #991870;
	background: url(images/backfoot1.png);
}.art_modal{
	max-width:800px;
}.art_modal_detail{
	padding: 12px;
	text-align: center;
	height: 812px;
    overflow-y: scroll;
    overflow-x: hidden;
}.art_modal_detail img{
	border: 3px solid #991870;
}.art_modal_detail h4{
	padding: 10px 0px;
	font-weight: 600;
	margin-bottom: 0;
}.art_modal_detail p{
	text-align: justify;
}.art_close{
	background: #991870;
	color: white;
	font-size: 16px;
	font-weight: 600;
}.view_offers{
	max-width: 600px;
}.form-control:focus{
	box-shadow: none !important;
}.left_div{
	float:left;
	width:15%;
}.right_div{
	float:right;
	width:85%;
}.feed_success{
	height: 120px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 84px;
}.offer_box input{
	width: 20px;
    height: 20px;
    position: absolute;
    left: -24px;
}.offer_box p{
	width: 20px;
	height: 20px;
	position: relative;
	left: 24px;
}.offer_boxx{
	margin-top: 20px;
    padding-left: 0px;
    border: 1px solid #545b6247;
    border-radius: 4px;
}.offer_box{
	padding: 12px;
}.offer_box label{
	font-weight: 600;
}.del_btnn{
	background:#42b162;
	border-color:#42b162;
}.modal-body{
	max-height: calc(100vh - 200px);
	overflow-y: auto;
}.show_pass{
	position: absolute;
    top: 65%;
    right: 22px;
    cursor: pointer;
}canvas{
	/*prevent interaction with the canvas*/
	pointer-events:none;
	background-image:url('images/captcha.jpg');
}#captcha{
	padding-top: 10px;
    padding-left: 28px;
    padding-bottom: 6px;
}.reload_captcha{
	float: right;
    padding-top: 8px;
    cursor: pointer;
    color: #0076f5;
}#g-recaptcha iframe{
	width:100% !important;
}.coach_profile_link{
	cursor: pointer;
    color: rgb(7, 114, 228);
}.site_lang{
	width: 140px;
	height: 26px;
    float: right;
    border-radius: 4px;
    font-weight: 600;
    background: white;
    padding-left: 8px;
	position: relative;
	cursor:pointer;
	text-align:left;
}.site_lang i{
	position: absolute;
    right: 4px;
    top: 3px;
}.main_right_div ul{
	text-align: right;
}.standardd li a i{
	background: #991870;
    color: white;
    width: 30px;
    height: 28px;
    font-size: 15px;
    text-align: center;
    line-height: 25px;
    border: 2px solid #fff;
    border-radius: 6px;
}.standardd{
	margin-bottom:0px;
}.site_lang_ul{
	float: right;
    position: absolute;
    right: 17px;
    list-style: none;
    top: 26px;
    background: white;
    z-index: 1;
    width: 140px;
    border-radius: 4px;
    padding: 4px 10px;
    text-align: left;
}.main_right_div{
	position: relative;
	margin:auto;
}.main_right_div ul li{
	padding-right:2px !important;
	padding-left:8px !important;
}.site_lang_ul li{
	cursor:pointer;
	width: 100%;
    text-align: left;
}.site_lang_ul li img{
	height: 18px;
    vertical-align: middle;
    width: 24px;
    object-fit: cover;
}.main_left_div{
	padding-left: 8px;
}.site_lang img{
	height: 15px;
    vertical-align: middle;
    width: 24px;
	object-fit: cover;
	margin-right:5px;
}




  

